import React, { useState } from 'react';
import {
  useColorMode, IconButton, Box, Flex, Text, Avatar, Container, useMediaQuery, useColorModeValue, VStack, Heading, Link, Center, Button, Tag, Wrap, WrapItem
} from '@chakra-ui/react';
import { MoonIcon, SunIcon, EmailIcon } from '@chakra-ui/icons';

function App() {
  const { colorMode, toggleColorMode } = useColorMode();
  const [rotate, setRotate] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const navBg = useColorModeValue('rgba(240, 231, 219, 0.8)', 'rgba(32, 32, 35, 0.8)');
  const linkColor = useColorModeValue('gray.800', 'white');
  const textColor = useColorModeValue('gray.600', 'gray.200');
  const footerColor = useColorModeValue('gray.700', 'gray.300');

  const handleClick = () => {
    setRotate(true);
    setTimeout(() => {
      toggleColorMode();
      setRotate(false);
    }, 500);
  };

  const projects = [
    {
      name: 'CPDProperty.com',
      description: 'An asset management platform built with React, MySQL, Express, and Chakra UI. CPDProperty.org offers an innovative approach to managing assets, enhancing user experience with responsive design and interactive features.',
      url: 'https://cpdproperty.com',
      tags: ['React', 'MySQL', 'Express', 'Chakra UI']
    },
    {
      name: 'FreeBudget.us',
      description: 'A financial planning tool created using React, Firebase, Firestore, and Chakra UI. FreeBudget.us helps users manage their finances through intuitive budgeting tools and real-time data synchronization.',
      url: 'https://freebudget.us',
      tags: ['React', 'Firebase', 'Firestore', 'Chakra UI']
    }
  ];

  return (
    <Box
      fontSize={isMobile ? "sm" : "md"}
      bg={colorMode === 'light' ? '#f0e7db' : '#202023'}
      minH="100vh"
      display="flex"
      flexDirection="column"
    >      
    <Container
  maxW="container.lg"
  position="sticky"
  top="0"
  zIndex={10}
  bg={navBg}
  style={{
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
  }}
>
        <Flex
          as="nav"
          align="center"
          justify="center"
          wrap="wrap"
          w="100%"
          mb={1}
          p={1}
          color={linkColor}
        >
          <IconButton
            size="md"
            fontSize="lg"
            aria-label={`Switch to ${colorMode === 'light' ? 'dark' : 'light'} mode`}
            variant="ghost"
            color="current"
            onClick={handleClick}
            icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            style={{
              position: 'absolute',
              right: 0,
              transform: rotate ? 'rotate(360deg)' : 'none',
              transition: 'transform 0.5s ease-in-out',
            }}
          />
          <Box display="flex" alignItems="center">
            <Avatar
              name="Orhan Gunes Biler"
              src="/profile.JPG"
              size="md"
              borderWidth="1px"
              borderColor={colorMode === 'light' ? 'gray.800' : 'teal.300'}
              borderStyle="solid"
              mr={2}
            />
            <Text fontSize="lg" fontWeight="semibold">Orhan G Biler</Text>
          </Box>
        </Flex>
      </Container>
      <VStack
        spacing={4}
        as="section"
        mt={{ base: 5, md: 10 }}
        mb={{ base: 5, md: 10 }}
        align="stretch"
        maxW="container.md"
        mx="auto"
        px={5}
      >
        <Heading as="h3" size="lg" textAlign="center" color={textColor}>
          About Me
        </Heading>
        <Text fontSize="md" color={textColor}>
          I'm Orhan Gunes Biler, a passionate self-taught web developer with a focus on modern web technologies. 
          With years of experience in developing high-quality, scalable applications, I specialize in React, Node.js, 
          and cloud services. I'm constantly exploring new technologies to solve real-world problems efficiently. 
          Let's connect and create something amazing together.
        </Text>
      </VStack>
      <VStack
        spacing={5}
        as="section"
        mt={{ base: 5, md: 10 }}
        mb={{ base: 5, md: 10 }}
        align="stretch"
        maxW="container.md"
        mx="auto"
        px={5}
      >
        <Heading as="h3" size="lg" textAlign="center" color={textColor}>
          Projects
        </Heading>
        {projects.map((project, index) => (
          <Box key={index} mb={5}>
            <Heading as="h4" size="md" textAlign="left" color={textColor}>
              {project.name}
            </Heading>
            <Text fontSize="md" color={textColor}>
              {project.description}
            </Text>
            <Link href={project.url} isExternal color={linkColor}>
              Visit {project.name.toLowerCase()}
            </Link>
            <Wrap spacing={2} mt={2}>
              {project.tags.map((tag, tagIndex) => (
                <WrapItem key={tagIndex}>
                  <Tag size="sm" colorScheme="teal">{tag}</Tag>
                </WrapItem>
              ))}
            </Wrap>
          </Box>
        ))}
      </VStack>
      <VStack
        spacing={4}
        as="section"
        mt={{ base: 5, md: 10 }}
        mb={{ base: 5, md: 10 }}
        align="stretch"
        maxW="container.md"
        mx="auto"
        px={5}
      >
        <Heading as="h3" size="lg" textAlign="center" color={textColor}>
          Contact Me
        </Heading>
        <Text fontSize="md" textAlign="center" color={textColor}>
          Have a question or want to work together?
        </Text>
        <Center>
          <Button leftIcon={<EmailIcon />} colorScheme="teal" variant="solid">
            <Link href="mailto:bilergunes@gmail.com" color={linkColor} isExternal>
              bilergunes@gmail.com
            </Link>
          </Button>
        </Center>
      </VStack>
      <Center as="footer" w="100%" py={4} mt={10} bg={navBg} color={footerColor}>
        <Text fontSize="sm">
          © 2024 - Developed by O. Biler
        </Text>
      </Center>
    </Box>
  );
}

export default App;
